/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  Decimal: { input: number; output: number }
  Json: { input: any; output: any }
}

export type Author = {
  __typename?: "Author"
  authorImageId?: Maybe<Scalars["ID"]["output"]>
  averageBookRating?: Maybe<Scalars["Decimal"]["output"]>
  bio?: Maybe<Scalars["String"]["output"]>
  books?: Maybe<PaginatedBooks>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  series?: Maybe<PaginatedSeries>
  slug?: Maybe<Scalars["String"]["output"]>
}

export type AuthorBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type AuthorSeriesArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type Book = {
  __typename?: "Book"
  authors: Array<Author>
  averageRating?: Maybe<Scalars["Decimal"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  myRating?: Maybe<Rating>
  myReadingStatus?: Maybe<ReadingStatus>
  myReview?: Maybe<Review>
  originalPublicationDate?: Maybe<Scalars["Date"]["output"]>
  positionInSeries?: Maybe<Scalars["String"]["output"]>
  primaryImageId?: Maybe<Scalars["ID"]["output"]>
  ratingsCount?: Maybe<Scalars["Json"]["output"]>
  reviews?: Maybe<PaginatedReviews>
  reviewsCount?: Maybe<Scalars["Int"]["output"]>
  seriesId?: Maybe<Scalars["ID"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  totalRatingsCount?: Maybe<Scalars["Int"]["output"]>
}

export type BookReviewsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

export type CsvImportCounts = {
  __typename?: "CsvImportCounts"
  ratings: Scalars["Int"]["output"]
  readingStatuses: Scalars["Int"]["output"]
  reviews: Scalars["Int"]["output"]
  shelves: Scalars["Int"]["output"]
  /** Number of books successfully processed. */
  successfulBooks: Scalars["Int"]["output"]
  /** Total number of books attempted to process. */
  totalBooks: Scalars["Int"]["output"]
}

export type CsvImportError = {
  __typename?: "CsvImportError"
  error?: Maybe<Scalars["String"]["output"]>
  line?: Maybe<Scalars["Int"]["output"]>
}

export type CsvImportResult = {
  __typename?: "CsvImportResult"
  counts?: Maybe<CsvImportCounts>
  errors?: Maybe<Array<Maybe<CsvImportError>>>
  status: CsvImportStatus
}

export enum CsvImportStatus {
  /** CSV import completed successfully. */
  Completed = "COMPLETED",
  /** CSV import failed. */
  Failed = "FAILED",
  /** CSV import is pending. */
  Pending = "PENDING",
  /** CSV import is in progress. */
  Processing = "PROCESSING",
}

export enum ImageFileType {
  /** User avatar image. */
  Avatar = "AVATAR",
  /** User banner image. */
  Banner = "BANNER",
}

export type PaginatedBooks = {
  __typename?: "PaginatedBooks"
  items: Array<Book>
  pagination: PaginationMeta
}

export type PaginatedReviewComments = {
  __typename?: "PaginatedReviewComments"
  items?: Maybe<Array<Maybe<ReviewComment>>>
  pagination?: Maybe<PaginationMeta>
}

export type PaginatedReviews = {
  __typename?: "PaginatedReviews"
  items?: Maybe<Array<Maybe<Review>>>
  pagination?: Maybe<PaginationMeta>
}

export type PaginatedSeries = {
  __typename?: "PaginatedSeries"
  items: Array<Series>
  pagination: PaginationMeta
}

export type PaginatedUserBooks = {
  __typename?: "PaginatedUserBooks"
  items: Array<UserBook>
  pagination: PaginationMeta
}

export type PaginationMeta = {
  __typename?: "PaginationMeta"
  page: Scalars["Int"]["output"]
  pageSize: Scalars["Int"]["output"]
  totalCount: Scalars["Int"]["output"]
  totalPages: Scalars["Int"]["output"]
}

export type Rating = {
  __typename?: "Rating"
  bookId?: Maybe<Scalars["ID"]["output"]>
  rating?: Maybe<Scalars["Int"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

export type ReadingStatus = {
  __typename?: "ReadingStatus"
  dateFinished?: Maybe<Scalars["Date"]["output"]>
  dateStarted?: Maybe<Scalars["Date"]["output"]>
  status: ReadingStatusEnum
}

export enum ReadingStatusEnum {
  CurrentlyReading = "CURRENTLY_READING",
  DidNotFinish = "DID_NOT_FINISH",
  OnHold = "ON_HOLD",
  Priority = "PRIORITY",
  Read = "READ",
  WantToRead = "WANT_TO_READ",
}

export type RegisterUserInput = {
  avatarId?: InputMaybe<Scalars["ID"]["input"]>
  displayName: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}

export type Review = {
  __typename?: "Review"
  bookId?: Maybe<Scalars["ID"]["output"]>
  comments?: Maybe<PaginatedReviewComments>
  commentsCount?: Maybe<Scalars["Int"]["output"]>
  content?: Maybe<Scalars["Json"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  isEdited?: Maybe<Scalars["Boolean"]["output"]>
  likedByMe?: Maybe<Scalars["Boolean"]["output"]>
  likesCount?: Maybe<Scalars["Int"]["output"]>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

export type ReviewCommentsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

export type ReviewComment = {
  __typename?: "ReviewComment"
  content?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  isEdited?: Maybe<Scalars["Boolean"]["output"]>
  likedByMe?: Maybe<Scalars["Boolean"]["output"]>
  likesCount?: Maybe<Scalars["Int"]["output"]>
  reviewId?: Maybe<Scalars["ID"]["output"]>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

export type RootMutationType = {
  __typename?: "RootMutationType"
  /** Add books to a shelf. */
  addBooksToShelf?: Maybe<ShelfBooksUpdateResponse>
  /** Create a new rating */
  createRating?: Maybe<Rating>
  /** Create a new review */
  createReview?: Maybe<Review>
  /** Create a new review comment */
  createReviewComment?: Maybe<ReviewComment>
  /** Create a new shelf. */
  createShelf?: Maybe<Shelf>
  /** Create a new user profile. */
  createUser?: Maybe<User>
  /** Delete the current user's account and all related data. */
  deleteAccount?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove the current reading status (along with dates read) for multiple books. */
  deleteReadingStatuses?: Maybe<ShelfBooksUpdateResponse>
  /** Delete a review by me */
  deleteReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Delete a review comment by me */
  deleteReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Delete a shelf */
  deleteShelf?: Maybe<Scalars["Boolean"]["output"]>
  /** Generate a presigned URL for uploading a file */
  generateUploadUrl?: Maybe<UploadUrlResponse>
  /** Like a review */
  likeReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Like a review comment */
  likeReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Process the uploaded CSV file */
  processCsvUpload?: Maybe<CsvImportResult>
  /** Process the uploaded image file */
  processImageUpload?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove books from a shelf. */
  removeBooksFromShelf?: Maybe<ShelfBooksUpdateResponse>
  /** Remove my existing rating for a book */
  removeRating?: Maybe<Scalars["Boolean"]["output"]>
  /** Rename a shelf */
  renameShelf?: Maybe<Shelf>
  /** Reset the user's library by deleting all their reviews, ratings and shelves. */
  resetLibrary?: Maybe<Scalars["Boolean"]["output"]>
  /** Set the reading status for multiple books. Update or insert if not present. */
  setReadingStatuses?: Maybe<ShelfBooksUpdateResponse>
  /** Unlike a review */
  unlikeReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Unlike a review comment */
  unlikeReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Update my existing rating for a book */
  updateRating?: Maybe<Rating>
  /** Update an existing review by me */
  updateReview?: Maybe<Review>
  /** Update an existing review comment by me */
  updateReviewComment?: Maybe<ReviewComment>
  /** Update the current user's profile. */
  updateUser?: Maybe<User>
}

export type RootMutationTypeAddBooksToShelfArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  shelfId: Scalars["ID"]["input"]
}

export type RootMutationTypeCreateRatingArgs = {
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}

export type RootMutationTypeCreateReviewArgs = {
  bookId: Scalars["ID"]["input"]
  content: Scalars["Json"]["input"]
}

export type RootMutationTypeCreateReviewCommentArgs = {
  content: Scalars["String"]["input"]
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeCreateShelfArgs = {
  name: Scalars["String"]["input"]
}

export type RootMutationTypeCreateUserArgs = {
  input: RegisterUserInput
}

export type RootMutationTypeDeleteReadingStatusesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationTypeDeleteReviewArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeDeleteReviewCommentArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeDeleteShelfArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeLikeReviewArgs = {
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeLikeReviewCommentArgs = {
  reviewCommentId: Scalars["ID"]["input"]
}

export type RootMutationTypeProcessCsvUploadArgs = {
  source: UploadSource
  uploadId: Scalars["ID"]["input"]
}

export type RootMutationTypeProcessImageUploadArgs = {
  imageType: ImageFileType
  uploadId: Scalars["ID"]["input"]
}

export type RootMutationTypeRemoveBooksFromShelfArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  shelfId: Scalars["ID"]["input"]
}

export type RootMutationTypeRemoveRatingArgs = {
  bookId: Scalars["ID"]["input"]
}

export type RootMutationTypeRenameShelfArgs = {
  id: Scalars["ID"]["input"]
  newName: Scalars["String"]["input"]
}

export type RootMutationTypeSetReadingStatusesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  dateFinished?: InputMaybe<Scalars["Date"]["input"]>
  dateStarted?: InputMaybe<Scalars["Date"]["input"]>
  status: ReadingStatusEnum
}

export type RootMutationTypeUnlikeReviewArgs = {
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeUnlikeReviewCommentArgs = {
  reviewCommentId: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateRatingArgs = {
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}

export type RootMutationTypeUpdateReviewArgs = {
  content: Scalars["Json"]["input"]
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateReviewCommentArgs = {
  content: Scalars["String"]["input"]
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput
}

export type RootQueryType = {
  __typename?: "RootQueryType"
  /** Get an author by ID. */
  author?: Maybe<Author>
  /** Get an author by slug. */
  authorBySlug?: Maybe<Author>
  /** Get a book by ID */
  book?: Maybe<Book>
  /** Get a book by slug */
  bookBySlug?: Maybe<Book>
  /** Get the currently authenticated user. */
  me?: Maybe<User>
  /** Get random users */
  randomUsers?: Maybe<Array<Maybe<User>>>
  /** Get recently reviewed books */
  recentlyReviewedBooks?: Maybe<Array<Maybe<Book>>>
  /** Get a review by its ID */
  review?: Maybe<Review>
  /** Get a review comment by its ID */
  reviewComment?: Maybe<ReviewComment>
  /** Search for author by name. */
  searchAuthors?: Maybe<Array<Maybe<Author>>>
  /** Search for books by title. */
  searchBooksByTitle?: Maybe<Array<Maybe<Book>>>
  /** Get a series by ID */
  series?: Maybe<Series>
  /** Get a series by slug */
  seriesBySlug?: Maybe<Series>
  /** Get paginated top rated books of all time */
  topRatedBooks?: Maybe<PaginatedBooks>
  /** Get top 10 books this week */
  trendingBooksWeekly?: Maybe<Array<Maybe<Book>>>
  /** Get trending reviews this week */
  trendingReviewsWeekly?: Maybe<Array<Maybe<Review>>>
  /** Get a single user by their ID. */
  user?: Maybe<User>
  /** Get a single user by their username */
  userByUsername?: Maybe<User>
}

export type RootQueryTypeAuthorArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeAuthorBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeBookArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeBookBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeRandomUsersArgs = {
  limit: Scalars["Int"]["input"]
}

export type RootQueryTypeReviewArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeReviewCommentArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeSearchAuthorsArgs = {
  name: Scalars["String"]["input"]
}

export type RootQueryTypeSearchBooksByTitleArgs = {
  title: Scalars["String"]["input"]
}

export type RootQueryTypeSeriesArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeSeriesBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeTopRatedBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type RootQueryTypeUserArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeUserByUsernameArgs = {
  username: Scalars["String"]["input"]
}

export type Series = {
  __typename?: "Series"
  books?: Maybe<PaginatedBooks>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

export type SeriesBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type Shelf = {
  __typename?: "Shelf"
  /** Get books on this shelf with the owner's reading status, rating, and review */
  books?: Maybe<PaginatedUserBooks>
  booksCount: Scalars["Int"]["output"]
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  slug: Scalars["String"]["output"]
  userId: Scalars["ID"]["output"]
}

export type ShelfBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

/** Response after updating books on a shelf */
export type ShelfBooksUpdateResponse = {
  __typename?: "ShelfBooksUpdateResponse"
  /** Number of books successfully updated. */
  successfulBooks: Scalars["Int"]["output"]
  /** Total number of books processed during the update. */
  totalBooksProcessed: Scalars["Int"]["output"]
}

export enum SortBy {
  /** Sort by most liked first */
  MostLiked = "MOST_LIKED",
  /** Sort by newest first */
  Newest = "NEWEST",
  /** Sort by oldest first */
  Oldest = "OLDEST",
}

export type UpdateUserInput = {
  avatarId?: InputMaybe<Scalars["ID"]["input"]>
  bannerId?: InputMaybe<Scalars["ID"]["input"]>
  bio?: InputMaybe<Scalars["String"]["input"]>
  displayName?: InputMaybe<Scalars["String"]["input"]>
  favoriteAuthors?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  favoriteBooks?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  instagramHandle?: InputMaybe<Scalars["String"]["input"]>
  tiktokHandle?: InputMaybe<Scalars["String"]["input"]>
  twitterHandle?: InputMaybe<Scalars["String"]["input"]>
  username?: InputMaybe<Scalars["String"]["input"]>
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>
}

export enum UploadSource {
  /** Goodreads CSV export. */
  Goodreads = "GOODREADS",
  /** StoryGraph CSV export. */
  Storygraph = "STORYGRAPH",
}

export type UploadUrlResponse = {
  __typename?: "UploadUrlResponse"
  uploadId: Scalars["ID"]["output"]
  uploadUrl: Scalars["String"]["output"]
}

export type User = {
  __typename?: "User"
  avatarId?: Maybe<Scalars["ID"]["output"]>
  averageRating?: Maybe<Scalars["Decimal"]["output"]>
  bannerId?: Maybe<Scalars["ID"]["output"]>
  bio?: Maybe<Scalars["String"]["output"]>
  displayName?: Maybe<Scalars["String"]["output"]>
  favoriteAuthors?: Maybe<Array<Maybe<Author>>>
  favoriteBooks?: Maybe<Array<Maybe<Book>>>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  instagramHandle?: Maybe<Scalars["String"]["output"]>
  /** Get all books in the user's library, including ratings, reviews, and reading status. */
  libraryBooks?: Maybe<PaginatedUserBooks>
  /** Get books from the library filtered by reading status, with ratings, reviews, and reading status. */
  libraryBooksByStatus?: Maybe<PaginatedUserBooks>
  /** Get a user's rating for a book by book ID */
  ratingForBook?: Maybe<Rating>
  ratingsCount?: Maybe<Scalars["Json"]["output"]>
  /** Get a user's review for a book slug (needs username too from parent object) */
  reviewForBook?: Maybe<Review>
  /** Get all reviews by a specific user */
  reviews?: Maybe<PaginatedReviews>
  reviewsCount?: Maybe<Scalars["Int"]["output"]>
  /** Get a specific shelf by slug */
  shelf?: Maybe<Shelf>
  /** Get all shelves for the user */
  shelves?: Maybe<Array<Maybe<Shelf>>>
  tiktokHandle?: Maybe<Scalars["String"]["output"]>
  totalRatingsCount?: Maybe<Scalars["Int"]["output"]>
  twitterHandle?: Maybe<Scalars["String"]["output"]>
  username?: Maybe<Scalars["String"]["output"]>
  websiteUrl?: Maybe<Scalars["String"]["output"]>
}

export type UserLibraryBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type UserLibraryBooksByStatusArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  status?: InputMaybe<ReadingStatusEnum>
}

export type UserRatingForBookArgs = {
  bookId: Scalars["ID"]["input"]
}

export type UserReviewForBookArgs = {
  bookSlug: Scalars["String"]["input"]
}

export type UserReviewsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

export type UserShelfArgs = {
  slug: Scalars["String"]["input"]
}

/** A book with the user's reading status, rating, and review. */
export type UserBook = {
  __typename?: "UserBook"
  book: Book
  rating?: Maybe<Rating>
  readingStatus?: Maybe<ReadingStatus>
  review?: Maybe<Review>
}

export type CreateUserMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type CreateUserMutation = {
  __typename?: "RootMutationType"
  createUser?: {
    __typename?: "User"
    username?: string | null
    displayName?: string | null
    id?: string | null
  } | null
}

export type CreateRatingMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}>

export type CreateRatingMutation = {
  __typename?: "RootMutationType"
  createRating?: {
    __typename?: "Rating"
    bookId?: string | null
    rating?: number | null
    userId?: string | null
  } | null
}

export type CreateReviewMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  content: Scalars["Json"]["input"]
}>

export type CreateReviewMutation = {
  __typename?: "RootMutationType"
  createReview?: {
    __typename?: "Review"
    bookId?: string | null
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    updatedAt?: string | null
    userId?: string | null
  } | null
}

export type DeleteReviewMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteReviewMutation = {
  __typename?: "RootMutationType"
  deleteReview?: boolean | null
}

export type DeleteReviewCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteReviewCommentMutation = {
  __typename?: "RootMutationType"
  deleteReviewComment?: boolean | null
}

export type UpdateRatingMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}>

export type UpdateRatingMutation = {
  __typename?: "RootMutationType"
  updateRating?: {
    __typename?: "Rating"
    bookId?: string | null
    rating?: number | null
    userId?: string | null
  } | null
}

export type UpdateReviewMutationVariables = Exact<{
  content: Scalars["Json"]["input"]
  id: Scalars["ID"]["input"]
}>

export type UpdateReviewMutation = {
  __typename?: "RootMutationType"
  updateReview?: {
    __typename?: "Review"
    bookId?: string | null
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    updatedAt?: string | null
    userId?: string | null
  } | null
}

export type CreateShelfMutationVariables = Exact<{
  name: Scalars["String"]["input"]
}>

export type CreateShelfMutation = {
  __typename?: "RootMutationType"
  createShelf?: {
    __typename?: "Shelf"
    id: string
    name: string
    slug: string
    userId: string
  } | null
}

export type DeleteReadingStatusesMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type DeleteReadingStatusesMutation = {
  __typename?: "RootMutationType"
  deleteReadingStatuses?: {
    __typename?: "ShelfBooksUpdateResponse"
    successfulBooks: number
    totalBooksProcessed: number
  } | null
}

export type DeleteShelfMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteShelfMutation = {
  __typename?: "RootMutationType"
  deleteShelf?: boolean | null
}

export type SetReadingStatusesMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  status: ReadingStatusEnum
  dateStarted?: InputMaybe<Scalars["Date"]["input"]>
  dateFinished?: InputMaybe<Scalars["Date"]["input"]>
}>

export type SetReadingStatusesMutation = {
  __typename?: "RootMutationType"
  setReadingStatuses?: {
    __typename?: "ShelfBooksUpdateResponse"
    successfulBooks: number
    totalBooksProcessed: number
  } | null
}

export type GenerateUploadUrlMutationVariables = Exact<{ [key: string]: never }>

export type GenerateUploadUrlMutation = {
  __typename?: "RootMutationType"
  generateUploadUrl?: {
    __typename?: "UploadUrlResponse"
    uploadId: string
    uploadUrl: string
  } | null
}

export type ProcessCsvUploadMutationVariables = Exact<{
  source: UploadSource
  uploadId: Scalars["ID"]["input"]
}>

export type ProcessCsvUploadMutation = {
  __typename?: "RootMutationType"
  processCsvUpload?: {
    __typename?: "CsvImportResult"
    status: CsvImportStatus
    counts?: {
      __typename?: "CsvImportCounts"
      successfulBooks: number
      ratings: number
      readingStatuses: number
      reviews: number
      shelves: number
      totalBooks: number
    } | null
    errors?: Array<{
      __typename?: "CsvImportError"
      error?: string | null
      line?: number | null
    } | null> | null
  } | null
}

export type ProcessImageUploadMutationVariables = Exact<{
  imageType: ImageFileType
  uploadId: Scalars["ID"]["input"]
}>

export type ProcessImageUploadMutation = {
  __typename?: "RootMutationType"
  processImageUpload?: boolean | null
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: "RootMutationType"
  updateUser?: {
    __typename?: "User"
    avatarId?: string | null
    bannerId?: string | null
    bio?: string | null
    displayName?: string | null
    id?: string | null
    instagramHandle?: string | null
    ratingsCount?: any | null
    tiktokHandle?: string | null
    twitterHandle?: string | null
    username?: string | null
    websiteUrl?: string | null
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    } | null> | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      title?: string | null
      primaryImageId?: string | null
    } | null> | null
  } | null
}

export type AuthorBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  page?: InputMaybe<Scalars["Int"]["input"]>
}>

export type AuthorBySlugQuery = {
  __typename?: "RootQueryType"
  authorBySlug?: {
    __typename?: "Author"
    name?: string | null
    authorImageId?: string | null
    averageBookRating?: number | null
    bio?: string | null
    books?: {
      __typename?: "PaginatedBooks"
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
      items: Array<{
        __typename?: "Book"
        title?: string | null
        slug?: string | null
        id?: string | null
        primaryImageId?: string | null
        authors: Array<{ __typename?: "Author"; name?: string | null }>
      }>
    } | null
    series?: {
      __typename?: "PaginatedSeries"
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
      items: Array<{
        __typename?: "Series"
        description?: string | null
        id?: string | null
        name?: string | null
        slug?: string | null
        books?: {
          __typename?: "PaginatedBooks"
          items: Array<{
            __typename?: "Book"
            slug?: string | null
            title?: string | null
            primaryImageId?: string | null
          }>
        } | null
      }>
    } | null
  } | null
}

export type MyFavoriteAuthorsQueryVariables = Exact<{ [key: string]: never }>

export type MyFavoriteAuthorsQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
    } | null> | null
  } | null
}

export type BookByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type BookByIdQuery = {
  __typename?: "RootQueryType"
  book?: {
    __typename?: "Book"
    id?: string | null
    primaryImageId?: string | null
    slug?: string | null
    title?: string | null
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status: ReadingStatusEnum
    } | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename?: "Review"
        id?: string | null
        commentsCount?: number | null
      } | null> | null
    } | null
  } | null
}

export type BookBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type BookBySlugQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename?: "Book"
    averageRating?: number | null
    description?: string | null
    totalRatingsCount?: number | null
    id?: string | null
    originalPublicationDate?: string | null
    positionInSeries?: string | null
    primaryImageId?: string | null
    ratingsCount?: any | null
    reviewsCount?: number | null
    seriesId?: string | null
    slug?: string | null
    title?: string | null
    authors: Array<{
      __typename?: "Author"
      name?: string | null
      slug?: string | null
      id?: string | null
    }>
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status: ReadingStatusEnum
    } | null
    myRating?: { __typename?: "Rating"; rating?: number | null } | null
  } | null
}

export type FavoriteBooksByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type FavoriteBooksByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    favoriteBooks?: Array<{
      __typename?: "Book"
      averageRating?: number | null
      description?: string | null
      id?: string | null
      originalPublicationDate?: string | null
      positionInSeries?: string | null
      primaryImageId?: string | null
      ratingsCount?: any | null
      reviewsCount?: number | null
      seriesId?: string | null
      slug?: string | null
      title?: string | null
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status: ReadingStatusEnum
      } | null
    } | null> | null
  } | null
}

export type HomeDataQueryVariables = Exact<{ [key: string]: never }>

export type HomeDataQuery = {
  __typename?: "RootQueryType"
  recentlyReviewedBooks?: Array<{
    __typename?: "Book"
    id?: string | null
    slug?: string | null
    primaryImageId?: string | null
  } | null> | null
  trendingBooksWeekly?: Array<{
    __typename?: "Book"
    id?: string | null
    primaryImageId?: string | null
    slug?: string | null
    title?: string | null
  } | null> | null
  topRatedBooks?: {
    __typename?: "PaginatedBooks"
    items: Array<{
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      primaryImageId?: string | null
    }>
  } | null
}

export type MyFavoriteBooksQueryVariables = Exact<{ [key: string]: never }>

export type MyFavoriteBooksQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    favoriteBooks?: Array<{
      __typename?: "Book"
      id?: string | null
      title?: string | null
      primaryImageId?: string | null
    } | null> | null
  } | null
}

export type TopRatedBooksQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type TopRatedBooksQuery = {
  __typename?: "RootQueryType"
  topRatedBooks?: {
    __typename?: "PaginatedBooks"
    items: Array<{
      __typename?: "Book"
      averageRating?: number | null
      description?: string | null
      id?: string | null
      originalPublicationDate?: string | null
      positionInSeries?: string | null
      primaryImageId?: string | null
      ratingsCount?: any | null
      totalRatingsCount?: number | null
      reviewsCount?: number | null
      seriesId?: string | null
      slug?: string | null
      title?: string | null
      authors: Array<{
        __typename?: "Author"
        id?: string | null
        slug?: string | null
        name?: string | null
      }>
    }>
    pagination: {
      __typename?: "PaginationMeta"
      page: number
      pageSize: number
      totalCount: number
      totalPages: number
    }
  } | null
}

export type BookReviewsBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  page: Scalars["Int"]["input"]
  pageSize: Scalars["Int"]["input"]
  sortBy?: InputMaybe<SortBy>
}>

export type BookReviewsBySlugQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename?: "Book"
    averageRating?: number | null
    totalRatingsCount?: number | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename?: "Review"
        bookId?: string | null
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        likesCount?: number | null
        likedByMe?: boolean | null
        updatedAt?: string | null
        userId?: string | null
        comments?: {
          __typename?: "PaginatedReviewComments"
          items?: Array<{
            __typename?: "ReviewComment"
            content?: string | null
            id?: string | null
            insertedAt?: string | null
            isEdited?: boolean | null
            likedByMe?: boolean | null
            likesCount?: number | null
            reviewId?: string | null
            updatedAt?: string | null
            userId?: string | null
          } | null> | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type GetReviewQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type GetReviewQuery = {
  __typename?: "RootQueryType"
  review?: {
    __typename?: "Review"
    bookId?: string | null
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    isEdited?: boolean | null
    likedByMe?: boolean | null
    likesCount?: number | null
    updatedAt?: string | null
    userId?: string | null
    comments?: {
      __typename?: "PaginatedReviewComments"
      items?: Array<{
        __typename?: "ReviewComment"
        content?: string | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likedByMe?: boolean | null
        likesCount?: number | null
        reviewId?: string | null
        updatedAt?: string | null
        userId?: string | null
      } | null> | null
    } | null
  } | null
}

export type ReviewCommentsQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type ReviewCommentsQuery = {
  __typename?: "RootQueryType"
  review?: {
    __typename?: "Review"
    commentsCount?: number | null
    comments?: {
      __typename?: "PaginatedReviewComments"
      items?: Array<{
        __typename?: "ReviewComment"
        content?: string | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likedByMe?: boolean | null
        likesCount?: number | null
        reviewId?: string | null
        updatedAt?: string | null
        userId?: string | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type TrendingReviewsWeeklyQueryVariables = Exact<{
  [key: string]: never
}>

export type TrendingReviewsWeeklyQuery = {
  __typename?: "RootQueryType"
  trendingReviewsWeekly?: Array<{
    __typename?: "Review"
    bookId?: string | null
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    updatedAt?: string | null
    userId?: string | null
  } | null> | null
}

export type UserRatingForBookQueryVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  userId: Scalars["ID"]["input"]
}>

export type UserRatingForBookQuery = {
  __typename?: "RootQueryType"
  user?: {
    __typename?: "User"
    ratingForBook?: {
      __typename?: "Rating"
      rating?: number | null
      userId?: string | null
      bookId?: string | null
    } | null
  } | null
}

export type UserReviewForBookQueryVariables = Exact<{
  bookSlug: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}>

export type UserReviewForBookQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    reviewForBook?: {
      __typename?: "Review"
      commentsCount?: number | null
      content?: any | null
      id?: string | null
      insertedAt?: string | null
      isEdited?: boolean | null
      likesCount?: number | null
      likedByMe?: boolean | null
      userId?: string | null
      updatedAt?: string | null
      bookId?: string | null
    } | null
  } | null
}

export type UserReviewsSortByQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type UserReviewsSortByQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename?: "Review"
        bookId?: string | null
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        updatedAt?: string | null
        userId?: string | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type SeriesBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type SeriesBySlugQuery = {
  __typename?: "RootQueryType"
  seriesBySlug?: {
    __typename?: "Series"
    description?: string | null
    id?: string | null
    name?: string | null
    slug?: string | null
    books?: {
      __typename?: "PaginatedBooks"
      items: Array<{
        __typename?: "Book"
        averageRating?: number | null
        description?: string | null
        id?: string | null
        originalPublicationDate?: string | null
        positionInSeries?: string | null
        primaryImageId?: string | null
        ratingsCount?: any | null
        reviewsCount?: number | null
        seriesId?: string | null
        slug?: string | null
        title?: string | null
        authors: Array<{
          __typename?: "Author"
          name?: string | null
          slug?: string | null
        }>
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type AllBooksByStatusQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type AllBooksByStatusQuery = {
  __typename?: "RootQueryType"
  currentlyReading?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          id?: string | null
        }
      }>
    } | null
  } | null
  read?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          id?: string | null
        }
      }>
    } | null
  } | null
  wantToRead?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          id?: string | null
        }
      }>
    } | null
  } | null
  priority?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          id?: string | null
        }
      }>
    } | null
  } | null
  onHold?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          id?: string | null
        }
      }>
    } | null
  } | null
}

export type AllBooksInLibraryQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type AllBooksInLibraryQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    libraryBooks?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        rating?: { __typename?: "Rating"; rating?: number | null } | null
        readingStatus?: {
          __typename?: "ReadingStatus"
          status: ReadingStatusEnum
          dateStarted?: string | null
          dateFinished?: string | null
        } | null
        book: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          primaryImageId?: string | null
          authors: Array<{
            __typename?: "Author"
            name?: string | null
            slug?: string | null
            id?: string | null
          }>
        }
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type BooksByCustomShelfSlugQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  slug: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type BooksByCustomShelfSlugQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    shelf?: {
      __typename?: "Shelf"
      id: string
      slug: string
      books?: {
        __typename?: "PaginatedUserBooks"
        items: Array<{
          __typename?: "UserBook"
          book: {
            __typename?: "Book"
            primaryImageId?: string | null
            id?: string | null
            slug?: string | null
            title?: string | null
            authors: Array<{
              __typename?: "Author"
              name?: string | null
              slug?: string | null
              id?: string | null
            }>
          }
          readingStatus?: {
            __typename?: "ReadingStatus"
            status: ReadingStatusEnum
            dateStarted?: string | null
            dateFinished?: string | null
          } | null
          rating?: { __typename?: "Rating"; rating?: number | null } | null
        }>
        pagination: {
          __typename?: "PaginationMeta"
          page: number
          pageSize: number
          totalCount: number
          totalPages: number
        }
      } | null
    } | null
  } | null
}

export type BooksByStatusQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  status: ReadingStatusEnum
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type BooksByStatusQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        rating?: { __typename?: "Rating"; rating?: number | null } | null
        readingStatus?: {
          __typename?: "ReadingStatus"
          status: ReadingStatusEnum
          dateStarted?: string | null
          dateFinished?: string | null
        } | null
        book: {
          __typename?: "Book"
          primaryImageId?: string | null
          id?: string | null
          slug?: string | null
          title?: string | null
          authors: Array<{
            __typename?: "Author"
            name?: string | null
            slug?: string | null
            id?: string | null
          }>
        }
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type CustomShelvesQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type CustomShelvesQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      booksCount: number
    } | null> | null
  } | null
}

export type CustomShelvesBooksQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type CustomShelvesBooksQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      books?: {
        __typename?: "PaginatedUserBooks"
        items: Array<{
          __typename?: "UserBook"
          book: {
            __typename?: "Book"
            id?: string | null
            title?: string | null
            slug?: string | null
            primaryImageId?: string | null
            myRating?: { __typename?: "Rating"; rating?: number | null } | null
            authors: Array<{
              __typename?: "Author"
              name?: string | null
              id?: string | null
              slug?: string | null
            }>
          }
        }>
        pagination: {
          __typename?: "PaginationMeta"
          page: number
          pageSize: number
          totalCount: number
          totalPages: number
        }
      } | null
    } | null> | null
  } | null
}

export type MyBooksByStatusQueryVariables = Exact<{
  status: ReadingStatusEnum
  page: Scalars["Int"]["input"]
  pageSize: Scalars["Int"]["input"]
}>

export type MyBooksByStatusQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          slug?: string | null
          primaryImageId?: string | null
        }
      }>
    } | null
  } | null
}

export type MyCountByStatusQueryVariables = Exact<{ [key: string]: never }>

export type MyCountByStatusQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    currentlyReading?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    read?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    wantToRead?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    priority?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    onHold?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    didNotFinish?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type UserStatusCountsQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type UserStatusCountsQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    currentlyReading?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    read?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    wantToRead?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    priority?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    onHold?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    didNotFinish?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type BooksReadByUserQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type BooksReadByUserQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type MyAvatarQueryVariables = Exact<{ [key: string]: never }>

export type MyAvatarQuery = {
  __typename?: "RootQueryType"
  me?: { __typename?: "User"; avatarId?: string | null } | null
}

export type MyUsernameQueryVariables = Exact<{ [key: string]: never }>

export type MyUsernameQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
  } | null
}

export type RandomUsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"]
}>

export type RandomUsersQuery = {
  __typename?: "RootQueryType"
  randomUsers?: Array<{
    __typename?: "User"
    avatarId?: string | null
    id?: string | null
    username?: string | null
  } | null> | null
}

export type UserAvatarQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type UserAvatarQuery = {
  __typename?: "RootQueryType"
  userByUsername?: { __typename?: "User"; avatarId?: string | null } | null
}

export type UserByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type UserByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    avatarId?: string | null
    bannerId?: string | null
    bio?: string | null
    displayName?: string | null
    id?: string | null
    insertedAt?: string | null
    instagramHandle?: string | null
    ratingsCount?: any | null
    tiktokHandle?: string | null
    twitterHandle?: string | null
    totalRatingsCount?: number | null
    averageRating?: number | null
    username?: string | null
    websiteUrl?: string | null
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      authorImageId?: string | null
      slug?: string | null
      name?: string | null
    } | null> | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename?: "Review"
        bookId?: string | null
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        updatedAt?: string | null
        userId?: string | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    avatarId?: string | null
    bannerId?: string | null
    bio?: string | null
    displayName?: string | null
    id?: string | null
    instagramHandle?: string | null
    ratingsCount?: any | null
    tiktokHandle?: string | null
    twitterHandle?: string | null
    username?: string | null
    websiteUrl?: string | null
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    } | null> | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      title?: string | null
      primaryImageId?: string | null
    } | null> | null
  } | null
}

export type UsernameByUserIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type UsernameByUserIdQuery = {
  __typename?: "RootQueryType"
  user?: { __typename?: "User"; username?: string | null } | null
}

export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RegisterUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const CreateRatingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRating" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rating" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRating" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rating" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rating" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRatingMutation,
  CreateRatingMutationVariables
>
export const CreateReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Json" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReviewMutation,
  CreateReviewMutationVariables
>
export const DeleteReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>
export const DeleteReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReviewCommentMutation,
  DeleteReviewCommentMutationVariables
>
export const UpdateRatingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateRating" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rating" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRating" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rating" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rating" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRatingMutation,
  UpdateRatingMutationVariables
>
export const UpdateReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Json" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>
export const CreateShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createShelf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateShelfMutation, CreateShelfMutationVariables>
export const DeleteReadingStatusesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReadingStatuses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReadingStatuses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "successfulBooks" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBooksProcessed" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReadingStatusesMutation,
  DeleteReadingStatusesMutationVariables
>
export const DeleteShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteShelf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteShelfMutation, DeleteShelfMutationVariables>
export const SetReadingStatusesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetReadingStatuses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateStarted" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFinished" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setReadingStatuses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateStarted" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateStarted" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFinished" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFinished" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "successfulBooks" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBooksProcessed" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetReadingStatusesMutation,
  SetReadingStatusesMutationVariables
>
export const GenerateUploadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GenerateUploadUrl" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateUploadUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uploadId" } },
                { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateUploadUrlMutation,
  GenerateUploadUrlMutationVariables
>
export const ProcessCsvUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "processCsvUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "source" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UploadSource" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "uploadId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "processCsvUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "source" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "source" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "uploadId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uploadId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "counts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "successfulBooks" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readingStatuses" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shelves" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalBooks" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      { kind: "Field", name: { kind: "Name", value: "line" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProcessCsvUploadMutation,
  ProcessCsvUploadMutationVariables
>
export const ProcessImageUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProcessImageUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "imageType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ImageFileType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "uploadId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "processImageUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "imageType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "imageType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "uploadId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uploadId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProcessImageUploadMutation,
  ProcessImageUploadMutationVariables
>
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "bannerId" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "instagramHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tiktokHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "twitterHandle" },
                },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const AuthorBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthorBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authorBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authorImageId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageBookRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "books" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "primaryImageId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "series" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "books" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primaryImageId",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorBySlugQuery, AuthorBySlugQueryVariables>
export const MyFavoriteAuthorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyFavoriteAuthors" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyFavoriteAuthorsQuery,
  MyFavoriteAuthorsQueryVariables
>
export const BookByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryImageId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "commentsCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookByIdQuery, BookByIdQueryVariables>
export const BookBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPublicationDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "positionInSeries" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryImageId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "seriesId" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myRating" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookBySlugQuery, BookBySlugQueryVariables>
export const FavoriteBooksByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FavoriteBooksByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "myReadingStatus" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalPublicationDate",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionInSeries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviewsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seriesId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FavoriteBooksByUsernameQuery,
  FavoriteBooksByUsernameQueryVariables
>
export const HomeDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "HomeData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentlyReviewedBooks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryImageId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trendingBooksWeekly" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryImageId" },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "topRatedBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: { kind: "IntValue", value: "9" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeDataQuery, HomeDataQueryVariables>
export const MyFavoriteBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyFavoriteBooks" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyFavoriteBooksQuery,
  MyFavoriteBooksQueryVariables
>
export const TopRatedBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TopRatedBooks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "topRatedBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "page" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalPublicationDate",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionInSeries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalRatingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviewsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seriesId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "authors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopRatedBooksQuery, TopRatedBooksQueryVariables>
export const BookReviewsBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookReviewsBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "bookId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "commentsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comments" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "content",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "insertedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isEdited",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "likedByMe",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "likesCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "reviewId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userId",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BookReviewsBySlugQuery,
  BookReviewsBySlugQueryVariables
>
export const GetReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEdited" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewQuery, GetReviewQueryVariables>
export const ReviewCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ReviewComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEdited" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewCommentsQuery, ReviewCommentsQueryVariables>
export const TrendingReviewsWeeklyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TrendingReviewsWeekly" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "trendingReviewsWeekly" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrendingReviewsWeeklyQuery,
  TrendingReviewsWeeklyQueryVariables
>
export const UserRatingForBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserRatingForBook" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bookId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserRatingForBookQuery,
  UserRatingForBookQueryVariables
>
export const UserReviewForBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserReviewForBook" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookSlug" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookSlug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commentsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "insertedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEdited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "likesCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "likedByMe" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bookId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserReviewForBookQuery,
  UserReviewForBookQueryVariables
>
export const UserReviewsSortByDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserReviewsSortBy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "bookId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "commentsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEdited" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserReviewsSortByQuery,
  UserReviewsSortByQueryVariables
>
export const SeriesBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SeriesBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seriesBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "books" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "originalPublicationDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "positionInSeries" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "primaryImageId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ratingsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seriesId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeriesBySlugQuery, SeriesBySlugQueryVariables>
export const AllBooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllBooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "currentlyReading" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "read" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "wantToRead" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "priority" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "onHold" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBooksByStatusQuery,
  AllBooksByStatusQueryVariables
>
export const AllBooksInLibraryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllBooksInLibrary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooks" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rating" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rating" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "readingStatus" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dateStarted",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dateFinished",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBooksInLibraryQuery,
  AllBooksInLibraryQueryVariables
>
export const BooksByCustomShelfSlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksByCustomShelfSlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelf" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "books" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "page" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "page" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "pageSize" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "pageSize" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "book" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primaryImageId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "authors",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "slug",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "readingStatus",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dateStarted",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dateFinished",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rating" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "rating",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pagination" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "page" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageSize" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalPages" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BooksByCustomShelfSlugQuery,
  BooksByCustomShelfSlugQueryVariables
>
export const BooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rating" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rating" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "readingStatus" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dateStarted",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dateFinished",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BooksByStatusQuery, BooksByStatusQueryVariables>
export const CustomShelvesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomShelves" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomShelvesQuery, CustomShelvesQueryVariables>
export const CustomShelvesBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomShelvesBooks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "books" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "book" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "myRating",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "rating",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "authors",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "slug",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primaryImageId",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pagination" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "page" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageSize" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalPages" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomShelvesBooksQuery,
  CustomShelvesBooksQueryVariables
>
export const MyBooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyBooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImageId",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyBooksByStatusQuery,
  MyBooksByStatusQueryVariables
>
export const MyCountByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyCountByStatus" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentlyReading" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "read" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "wantToRead" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "priority" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "onHold" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "didNotFinish" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "DID_NOT_FINISH" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCountByStatusQuery,
  MyCountByStatusQueryVariables
>
export const UserStatusCountsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserStatusCounts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentlyReading" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "read" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "wantToRead" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "priority" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "onHold" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "didNotFinish" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "DID_NOT_FINISH" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserStatusCountsQuery,
  UserStatusCountsQueryVariables
>
export const BooksReadByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksReadByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BooksReadByUserQuery,
  BooksReadByUserQueryVariables
>
export const MyAvatarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyAvatar" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyAvatarQuery, MyAvatarQueryVariables>
export const MyUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyUsername" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyUsernameQuery, MyUsernameQueryVariables>
export const RandomUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RandomUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "randomUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RandomUsersQuery, RandomUsersQueryVariables>
export const UserAvatarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserAvatar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAvatarQuery, UserAvatarQueryVariables>
export const UserByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "bannerId" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "instagramHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tiktokHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "twitterHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "authorImageId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "bookId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "commentsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEdited" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserByUsernameQuery, UserByUsernameQueryVariables>
export const MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Me" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "bannerId" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "instagramHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tiktokHandle" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "twitterHandle" },
                },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImageId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const UsernameByUserIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UsernameByUserId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsernameByUserIdQuery,
  UsernameByUserIdQueryVariables
>
